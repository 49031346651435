import { useEffect } from "react";
import { appMode } from "../Utils/constants";

interface useScriptProps {
    url: string;
    scriptAsync?: boolean;
    mode?: string | undefined;
    disabled?: boolean | undefined;
};

export const useScript = ({ url, scriptAsync, mode, disabled }: useScriptProps): void => {
    useEffect(() => {
        const scriptName = "kisss-embed-script";

        if (disabled ?? false) {
            const scriptElement = document.getElementById(scriptName);
            if (scriptElement) {
                document.body.removeChild(scriptElement);
                console.log("useScript: disabled =" + disabled);
            }

            const iframeElement = document.getElementById('kisss-frame');
            if (iframeElement) {
                document.body.removeChild(iframeElement);
                console.log("useScript: disabled =" + disabled);
            }

            return;
        }

        const queryString = url.substring(url.indexOf('?') + 1);
        //console.log(queryString);
        const urlParams = new URLSearchParams(queryString);
        urlParams.forEach((value, key) => {
            if (!document.getElementById(key)) {
                const input = document.createElement('input');
                input.setAttribute('hidden', '');
                input.setAttribute('readonly', '');
                input.id = key;
                input.value = urlParams.get(key) ?? '';
                document.body.appendChild(input);
                // console.log(`${key}, ${value}`);
            }
        });

        if (!document.getElementById(scriptName)) {
            const script = document.createElement('script');
            script.id = scriptName;
            const src = `https://${appMode.apiEndpointBase}/api/file/1/${url.substring(0, url.indexOf('?'))}`;
            script.src = mode === 'debug' ? `${src}/debug` : src;
            //script.src = url;
            script.async = scriptAsync ?? false;
            document.body.appendChild(script);
        }

        return () => {
            // const script = document.getElementById(scriptName);
            // if (script) {
            //     document.body.removeChild(script);
            // }
        }
    }, [url, scriptAsync, mode, disabled]);
}