import { DefaultButton } from '@fluentui/react/lib/Button';
import { useEffect, useState } from 'react';
import { DisplayMode } from '../PickerComponentTypes';
import './../PickerComponentBase.css';

export type MonthPickerComponentProps = {
  from?: Date | undefined;
  to?: Date | undefined;
  displayMode?: DisplayMode | undefined;
  onSelectionChanged: (e: Date | undefined) => void
}

function MonthPickerComponent(props: MonthPickerComponentProps) {

  function monthDiff(dateFrom: Date, dateTo: Date) {
    return dateTo.getMonth() - dateFrom.getMonth() +
      (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
  }

  const { from, to, onSelectionChanged, displayMode } = props;

  const [selectedMonth, setSelectedMonth] = useState<Date | undefined>();
  const [availableMonths, setAvailableMonths] = useState<Date[]>([]);


  useEffect(() => {
    setAvailableMonths([]);
    const s = (from ?? new Date(Date.now()));
    const e = to ?? new Date((new Date(s)).setMonth(s.getMonth() + 6));

    const numberOfMonths = monthDiff(s, e);
    const months = Array
      .from(Array(numberOfMonths).keys())
      .map((v) => new Date(new Date(s).setMonth(s.getMonth() + v)))
      .map(v => new Date(v.getFullYear(), v.getMonth(), 1, 0, 0, 0, 0));

    setAvailableMonths(months);
  }, [from, to])

  return (
    <div className='calendar-month-picker-component-container'>
      {availableMonths && (availableMonths.filter(v => !(displayMode && displayMode === 'Minimized' && selectedMonth && selectedMonth !== v)).map(v =>
        <DefaultButton className='toggleButtonStyle' toggle={true} checked={v === selectedMonth} key={v.getTime()} onClick={() => {
          if (selectedMonth !== v) {
            setSelectedMonth(v);
            onSelectionChanged(v);
          }
          else if (selectedMonth === v) {
            setSelectedMonth(undefined);
            onSelectionChanged(undefined);
          }
        }
        }>{v.toLocaleDateString('da-DK', { month: 'long', year: 'numeric' })}</DefaultButton>
      ))
      }
    </div >
  );
}

export default MonthPickerComponent;