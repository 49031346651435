import { DefaultButton } from '@fluentui/react/lib/Button';
import { useState } from 'react';
import { DisplayMode } from '../PickerComponentTypes';
import './../PickerComponentBase.css';

export type TopicPickerComponentProps = {
  availableTopics: string[]
  displayMode?: DisplayMode | undefined;
  onSelectionChanged: (e: string | undefined) => void
}

function TopicPickerComponent(props: TopicPickerComponentProps) {

  const { availableTopics, onSelectionChanged, displayMode } = props;
  const [selectedTopic, setSelectedTopic] = useState<string | undefined>();

  return (
    <div className='calendar-topic-picker-component-container'>
      {availableTopics && (availableTopics.filter(v => !(displayMode && displayMode === 'Minimized' && selectedTopic && selectedTopic !== v)).map(v =>
        <DefaultButton className='toggleButtonStyle' toggle={true} checked={v === selectedTopic} key={v} onClick={() => {
          if (selectedTopic !== v) {
            setSelectedTopic(v);
            onSelectionChanged(v);
          }
          else if (selectedTopic === v) {
            setSelectedTopic(undefined);
            onSelectionChanged(undefined);
          }
        }
        }>{v}</DefaultButton>
      ))
      }
    </div >
  );
}

export default TopicPickerComponent;