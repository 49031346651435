import { NavLink } from 'react-router-dom';
import { Text } from '@fluentui/react';

import './MenuComponent.css';
import { useEffect } from 'react';

function MenuComponent() {
  const location = window.location.pathname;

  useEffect(() => {
    // Get the navbar
    const navbar = document.getElementById("navbar");

    // Get the offset position of the navbar
    const sticky = navbar?.offsetTop;

    // Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
    function myFunction() {

      if (sticky && navbar && window.pageYOffset >= sticky) {
        navbar.classList.add("sticky")
      } else if (navbar) {
        navbar.classList.remove("sticky");
      }
    }

    window.addEventListener("scroll", myFunction);
    window.addEventListener("DOMContentLoaded", myFunction);

    return () => {
      window.removeEventListener("scroll", myFunction);
      window.removeEventListener("DOMContentLoaded", myFunction);
    };

  }, [location]);

  return (
    <>
      <div className='menu-header'>
        <Text className='block' variant='xxLarge'>KISSS</Text>
        <Text className='block' variant='large'>Live chat with your website customers directly from Microsoft Teams</Text>
      </div>

      <nav id='navbar'>
        <ul>
          <li>
            <NavLink end to='/'>Home</NavLink>
          </li>
          {/* <li>
            <NavLink end to='/features'>Features</NavLink>
          </li> */}
          <li>
            <NavLink end to='/subscriptions'>Subscriptions</NavLink>
          </li>
          <li>
            <NavLink end to='/contact'>Contact</NavLink>
          </li>
          {/* <li>
            <NavLink end to='/tryme'>Try Me</NavLink>
          </li> */}
        </ul>
      </nav>
    </>
  );
}

export default MenuComponent;
