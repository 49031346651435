// © frwrd Corporation. All rights reserved.

export const HttpClientHeaders = {
  httpGetHeadersWithAccessToken: (accessToken: string): RequestInit => {
    return {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Response-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      }
    }
  },
  httpPostSettingsWithAccessToken: (body: any, accessToken: string): RequestInit => {
    if (body instanceof FormData) {
      return {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
        body: body
      }
    }
    else {
      return {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
        body: JSON.stringify(body)
      }
    }
  },
  httpPutSettingsWithAccessToken: (body: any, accessToken: string): RequestInit => {
    if (body instanceof FormData) {
      return {
        method: 'PUT',
        mode: 'cors',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
        body: body
      }
    }
    else {
      return {
        method: 'PUT',
        mode: 'cors',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
        body: JSON.stringify(body)
      }
    }
  },
  httpDeleteSettingsWithAccessToken: (accessToken: string): RequestInit => {
    return {
      method: 'DELETE',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    }
  },
  httpPostSettings: (body: any, abortSignal: AbortSignal): RequestInit => {
    return {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
      signal: abortSignal
    }
  },
  httpPutSettings: (body: any) => {
    return {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    }
  },
  httpDeleteSettings: (): RequestInit => {
    return {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  },
  httpGetHeaders: (abortSignal: AbortSignal): RequestInit => {
    return {
      method: 'GET',
      headers: {
        'Response-Type': 'application/json',
      },
      signal: abortSignal
    }
  }
};