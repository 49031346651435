import { Configuration, LogLevel, RedirectRequest } from "@azure/msal-browser";

export const appMode = {
  apiEndpointBase: process.env.REACT_APP_AppMode_apiEndpointBase,
  mode: process.env.REACT_APP_Mode,
  chatMode: process.env.REACT_APP_ChatMode ?? 'teams',
  debugOn: (process.env.REACT_APP_DebugOn ?? false) === 'true',
  suppressAll: (process.env.REACT_APP_SuppressAll ?? true) === 'true',
}

export const acsConfig = {
  endpointUrl: `https://${process.env.REACT_APP_AcsConfig_endpointName ?? ''}.communication.azure.com`,
  groupId: '11111111-1111-1111-1111-000000000002',
}

export const msalConfig: Configuration = {
  // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md#usemsalauthentication-hook
  // https://github.com/Azure-Samples/ms-identity-javascript-react-tutorial/blob/main/3-Authorization-II/1-call-api/SPA/src/pages/Hello.jsx
  // https://docs.microsoft.com/en-us/azure/active-directory/develop/msal-js-initializing-client-applications
  auth: {
    redirectUri: '/',
    clientId: '115dfbad-ab0a-49c8-9bf8-d30b53f2e4ec',
    authority: 'https://login.microsoftonline.com/0336ea8e-b9cd-49e3-b0eb-ac14bec72251', // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    navigateToLoginRequestUrl: true,
    knownAuthorities: [],
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            // ignore info
            // console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      }
    }
  }
};

export const msalAuthenticationRequest: RedirectRequest = {
  scopes: ["User.Read"],
};

export const msalAuthenticationPopupRequest: RedirectRequest = {
  scopes: ["User.Read"],
};

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = {
  graphMe: {
    endpoint: "https://graph.microsoft.com/v1.0/me",
    scopes: ["User.Read"],
  },
  apiWeatherForecast: {
    endpoint: `https://${appMode.apiEndpointBase}`,
    scopes: ["api://f1d03656-e830-4c1e-87ea-8a9531d0f671/access_as_user"], // e.g. api://xxxxxx/access_as_user
  },
  apiAcsToken: {
    endpoint: `https://${appMode.apiEndpointBase}`,
    scopes: ["api://f1d03656-e830-4c1e-87ea-8a9531d0f671/access_as_user"], // e.g. api://xxxxxx/access_as_user
  },
  apiAcsChatThread: {
    endpoint: `https://${appMode.apiEndpointBase}`,
    scopes: ["api://f1d03656-e830-4c1e-87ea-8a9531d0f671/access_as_user"], // e.g. api://xxxxxx/access_as_user
  },
}