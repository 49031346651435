import { FontIcon } from '@fluentui/react';
import TemplatePageBase from '../template/TemplatePageBase';
import './ContactPage.css';

function ContactPage() {

  return (
    <TemplatePageBase>
      <div className='body-container'>
        <div className='item-container'>
          <div className='contact-card' >
            <div className='contact-card-header-icon' >
              <FontIcon aria-label="Contact" className='icon-size' iconName="CityNext" />
            </div>
            <div className='contact-card-content' >
              <span className='contact-card-header'>KISSS by FRWRD</span>
              <address>

                <span className='contact-card-text'>Martin Esmann</span>
                <span className='contact-card-text'>E-mail: <a href="mailto:hello@frwrd.io">hello@frwrd.io</a></span>
                <span className='contact-card-text'>CVR: <a href="https://datacvr.virk.dk/enhed/virksomhed/35581251">35581251</a></span>
              </address>


            </div>
          </div>
        </div>
      </div>
    </TemplatePageBase >
  );
}

export default ContactPage;