import { useState } from 'react';
import './PickerComponentBase.css';
import DayPickerComponent from './DayPicker/DayPickerComponent';
import MonthPickerComponent from './MonthPicker/MonthPickerComponent';
import TimeSlotPickerComponent from './TimeSlotPicker/TimeSlotPickerComponent';
import { DisplayMode, TimeInterval } from './PickerComponentTypes';
import { FontIcon } from '@fluentui/react/lib/Icon';
import TimeSlotConfirmationPickerComponent from './TimeSlotConfirmationPicker/TimeSlotConfirmationPickerComponent';
import TopicPickerComponent from './TopicPicker/TopicPickerComponent';
import CommentPickerComponent from './CommentPicker/CommentPickerComponent';

export type CalenderPickerComponentProps = {
  headerText?: string | undefined;
  topics?: string[] | undefined;
  from?: Date | undefined;
  to?: Date | undefined
  allowPastDateSelections?: boolean | undefined;
  displayMode?: DisplayMode | undefined;
  firstHour?: number | undefined;
  firstMinute?: number | undefined;
  lastHour?: number | undefined;
  excludedHours?: number[] | undefined;
  allowedHours?: number[] | undefined;
  lastMinute?: number | undefined;
  timeSlotBreakMinutes?: number | undefined;
  timeSlotMinutes?: number | undefined;
  onTopicSelectionChanged?: (e: string | undefined) => void;
  onCommentSelectionChanged?: (e: string | undefined) => void;
  onMonthSelectionChanged?: (e: Date | undefined) => void;
  onDaySelectionChanged?: (e: Date | undefined) => void;
  onTimeSlotSelectionChanged?: (e: TimeInterval | undefined) => void;
}

function CalenderPickerComponent(props: CalenderPickerComponentProps) {
  const { headerText, topics, from, to, allowPastDateSelections, firstHour, firstMinute, lastHour, lastMinute, timeSlotBreakMinutes, timeSlotMinutes, displayMode, onCommentSelectionChanged, onTopicSelectionChanged, onTimeSlotSelectionChanged, onDaySelectionChanged, onMonthSelectionChanged } = props;

  const [selectedTopic, setSelectedTopic] = useState<string | undefined>();
  const [selectedComment, setSelectedComment] = useState<string | undefined>();

  const [selectedMonth, setSelectedMonth] = useState<Date | undefined>();
  const [selectedDay, setSelectedDay] = useState<Date | undefined>();
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<TimeInterval | undefined>();

  return (
    <div className='calendar-component-container'>

      <div className='calendar-fragment-header'>
        {headerText ?? 'Booking'}
      </div>

      <div className='calendar-fragment-header'>
        <FontIcon aria-label="topic" iconName="Questionnaire" />
        <sub>Topic</sub>
      </div>
      <TopicPickerComponent availableTopics={topics ?? ['Topic1', 'Topic2']} displayMode={displayMode} onSelectionChanged={(d) => {
        setSelectedTopic(d);
        if (!d) {
          setSelectedMonth(undefined);
          setSelectedDay(undefined);
          setSelectedTimeSlot(undefined);
        }
        if (onTopicSelectionChanged) onTopicSelectionChanged(d);
      }} />

      {selectedTopic && (
        <>
          <div className='calendar-fragment-header'>
            <FontIcon aria-label="comment" iconName="Comment" />
            <sub>Comment</sub>
          </div>
          <CommentPickerComponent value={selectedComment} onSelectionChanged={(d) => {
            setSelectedComment(d);
            if (onCommentSelectionChanged) onCommentSelectionChanged(d);
          }} />
        </>
      )}

      {selectedTopic && (
        <>
          <div className='calendar-fragment-header'>
            <FontIcon aria-label="month" iconName="PlanView" />
            <sub>Month</sub>
          </div>

          <MonthPickerComponent from={from} to={to} displayMode={displayMode} onSelectionChanged={(d) => {
            setSelectedMonth(d);
            if (!d) {
              setSelectedDay(undefined);
              setSelectedTimeSlot(undefined);
            }
            if (onMonthSelectionChanged) onMonthSelectionChanged(d);
          }} />
        </>
      )}

      {selectedTopic && selectedMonth && (
        <>
          <div className='calendar-fragment-header'>
            <FontIcon aria-label="calendarDay" iconName="Calendar" />
            <sub>Day</sub>
          </div>
          <DayPickerComponent displayMode={displayMode} from={allowPastDateSelections ? from : new Date(Date.now())} month={selectedMonth} onSelectionChanged={(d) => {
            setSelectedDay(d);
            if (!d) {
              setSelectedTimeSlot(undefined);
            }
            if (onDaySelectionChanged) onDaySelectionChanged(d);
          }} />
        </>
      )}

      {selectedDay && selectedMonth && (
        <>
          <div className='calendar-fragment-header'>
            <FontIcon aria-label="clock" iconName="Clock" />
            <sub>Time</sub>
          </div>
          <TimeSlotPickerComponent displayMode={displayMode} from={allowPastDateSelections ? from : new Date(Date.now())} firstHour={firstHour ?? 0} firstMinute={firstMinute ?? 0} lastHour={lastHour ?? 23} lastMinute={lastMinute ?? 59} timeSlotBreakMinutes={timeSlotBreakMinutes ?? 5} timeSlotMinutes={timeSlotMinutes ?? 10} day={selectedDay} onSelectionChanged={(d) => {
            setSelectedTimeSlot(d);
            if (onTimeSlotSelectionChanged) onTimeSlotSelectionChanged(d);
          }} />
        </>
      )}

      {selectedTimeSlot && selectedDay && (
        <>
          <div className='calendar-fragment-header'>
            {/* <FontIcon aria-label="calendarDay" iconName="CalendarDay" /> */}
            <FontIcon aria-label="gotoToday" iconName="GotoToday" />
            <sub>Confirm</sub>
          </div>
          <TimeSlotConfirmationPickerComponent
            timeSlot={selectedTimeSlot}
            onTimeout={() => {
              console.log('Timeout');
            }}
            onConfirm={() => {
              console.log('Confirmed');
            }} />

        </>
      )}
    </div>
  );
}

export default CalenderPickerComponent;