
import { Call, CallAgent, CallClient, CallState } from '@azure/communication-calling';
import { ChatClient, ChatThreadClient } from '@azure/communication-chat';
import { ChatMessage } from '@azure/communication-react';
import { AnyAction, Dispatch } from 'redux';
import { RootState } from '../../store';
import { ChatState } from './callTypes';

const SET_CALL_STATE = 'SET_CALL_STATE';
const SET_CHAT_STATE = 'SET_CHAT_STATE';
const SET_CALL_AGENT = 'SET_CALL_AGENT';
const SET_CALL = 'SET_CALL';
const SET_CALL_CLIENT = 'SET_CALL_CLIENT';
const SET_CHAT_CLIENT = 'SET_CHAT_CLIENT';
const SET_CHAT_THREAD_CLIENT = 'SET_CHAT_THREAD_CLIENT';
const ADD_CHAT_MESSAGE = 'ADD_CHAT_MESSAGE';
const UPDATE_CHAT_MESSAGE = 'UPDATE_CHAT_MESSAGE';
const RESET_CHAT_MESSAGES = 'RESET_CHAT_MESSAGES';

const SEND_OFFLINE_CHAT_MESSAGES = 'SEND_OFFLINE_CHAT_MESSAGES';
const END_CALL = 'END_CALL';


interface SetCallStateAction extends AnyAction {
    type: typeof SET_CALL_STATE;
    value?: CallState;
}

interface SetChatStateAction extends AnyAction {
    type: typeof SET_CHAT_STATE;
    value?: ChatState;
}

interface SetCallAgentAction extends AnyAction {
    type: typeof SET_CALL_AGENT;
    value?: CallAgent;
}

interface SetCallAction extends AnyAction {
    type: typeof SET_CALL;
    value?: Call;
}

interface SetCallClientAction extends AnyAction {
    type: typeof SET_CALL_CLIENT;
    value?: CallClient;
}

interface SetChatClientAction extends AnyAction {
    type: typeof SET_CHAT_CLIENT;
    value?: ChatClient;
}

interface SetChatThreadClientAction extends AnyAction {
    type: typeof SET_CHAT_THREAD_CLIENT;
    value?: ChatThreadClient;
}

interface AddChatMessageAction extends AnyAction {
    type: typeof ADD_CHAT_MESSAGE;
    value?: ChatMessage;
}

interface UpdateChatMessageAction extends AnyAction {
    type: typeof UPDATE_CHAT_MESSAGE;
    value?: ChatMessage;
}

interface ResetChatMessagesAction extends AnyAction {
    type: typeof RESET_CHAT_MESSAGES;
    value?: string;
}

interface SendOfflineChatMessagesAction extends AnyAction {
    type: typeof SEND_OFFLINE_CHAT_MESSAGES;
    value?: string;
}

interface EndCallAction extends AnyAction {
    type: typeof END_CALL;
    value?: string;
}


export const SetCallState = (value: CallState): SetCallStateAction => {
    return {
        type: SET_CALL_STATE,
        value
    };
};

export const SetChatState = (value: ChatState): SetChatStateAction => {
    return {
        type: SET_CHAT_STATE,
        value
    };
};

export const SetCallAgent = (value: CallAgent): SetCallAgentAction => {
    return {
        type: SET_CALL_AGENT,
        value
    };
};

export const SetCallClient = (value: CallClient): SetCallClientAction => {
    return {
        type: SET_CALL_CLIENT,
        value
    };
};

export const SetChatClient = (value: ChatClient): SetChatClientAction => {
    return {
        type: SET_CHAT_CLIENT,
        value
    };
};

export const SetChatThreadClient = (value: ChatThreadClient): SetChatThreadClientAction => {
    return {
        type: SET_CHAT_THREAD_CLIENT,
        value
    };
};


export const AddChatMessage = (value: ChatMessage): AddChatMessageAction => {
    return {
        type: ADD_CHAT_MESSAGE,
        value
    };
};

export const UpdateChatMessage = (value: ChatMessage): UpdateChatMessageAction => {
    return {
        type: UPDATE_CHAT_MESSAGE,
        value
    };
};

export const ResetChatMessages = (value: string): ResetChatMessagesAction => {
    return {
        type: RESET_CHAT_MESSAGES,
        value
    };
};

export const SendOfflineChatMessages = (value: string): SendOfflineChatMessagesAction => {
    return {
        type: SEND_OFFLINE_CHAT_MESSAGES,
        value
    };
};

export const EndCall = (value: string): EndCallAction => {
    return {
        type: END_CALL,
        value
    };
};

export const SetCall = (value: Call): SetCallAction => {
    return {
        type: SET_CALL,
        value
    };
};

export { SET_CALL_STATE, SET_CHAT_STATE, SET_CALL_AGENT, SET_CALL, SET_CALL_CLIENT, SET_CHAT_CLIENT, SET_CHAT_THREAD_CLIENT, ADD_CHAT_MESSAGE, UPDATE_CHAT_MESSAGE, SEND_OFFLINE_CHAT_MESSAGES, END_CALL, RESET_CHAT_MESSAGES };

export type CallTypes = SetCallStateAction | SetChatStateAction | SetCallAgentAction | SetCallAction | SetCallClientAction | SetChatClientAction | SetChatThreadClientAction | AddChatMessageAction | UpdateChatMessageAction | SendOfflineChatMessagesAction | EndCallAction | ResetChatMessagesAction;


// thunks - side effects
export const setCallState = (value: CallState) => {
    return async (dispatch: Dispatch/*, getState: () => State*/): Promise<any> => {
        dispatch(SetCallState(value));
    };
};

export const setChatState = (value: ChatState) => {
    return async (dispatch: Dispatch/*, getState: () => State*/): Promise<any> => {
        dispatch(SetChatState(value));
    };
};

export const setCallAgent = (value: CallAgent) => {
    return async (dispatch: Dispatch/*, getState: () => State*/): Promise<any> => {
        dispatch(SetCallAgent(value));
    };
};

export const setCall = (value: Call) => {
    return async (dispatch: Dispatch/*, getState: () => State*/): Promise<any> => {
        dispatch(SetCall(value));
    };
};

export const setCallClient = (value: CallClient) => {
    return async (dispatch: Dispatch/*, getState: () => State*/): Promise<any> => {
        dispatch(SetCallClient(value));
    };
};

export const setChatClient = (value: ChatClient) => {
    return async (dispatch: Dispatch/*, getState: () => State*/): Promise<any> => {
        dispatch(SetChatClient(value));
    };
};

export const setChatThreadClient = (value: ChatThreadClient) => {
    return async (dispatch: Dispatch/*, getState: () => State*/): Promise<any> => {
        dispatch(SetChatThreadClient(value));
    };
};

export const addChatMessage = (value: ChatMessage) => {
    return async (dispatch: Dispatch/*, getState: () => State*/): Promise<any> => {
        dispatch(AddChatMessage(value));
    };
};

export const updateChatMessage = (value: ChatMessage) => {
    return async (dispatch: Dispatch/*, getState: () => State*/): Promise<any> => {
        dispatch(UpdateChatMessage(value));
    };
};

export const resetChatMessages = (value: string) => {
    return async (dispatch: Dispatch/*, getState: () => State*/): Promise<any> => {
        dispatch(ResetChatMessages(value));
    };
};

export const sendOfflineChatMessages = (value: string) => {
    return async (dispatch: Dispatch, getState: () => RootState): Promise<any> => {

        const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

        const state = getState();
        const callState = state.call;
        if (!callState.chatThreadClient) return;

        const chatMessages = callState.chatMessages;
        const offlineMessages = chatMessages.filter(m => m.mine && m.metadata && m.metadata['state'] === 'offline')
            .sort((a, b) => a.createdOn.getTime() - b.createdOn.getTime());

        for (let key in offlineMessages) {
            const chatMessage = offlineMessages[key];
            if (chatMessage.content) {

                try {
                    const res = await callState.chatThreadClient.sendMessage({ content: chatMessage.content }, { senderDisplayName: value });
                    chatMessage.metadata = { state: 'delivered', id: res.id };

                    //@ts-ignore
                    dispatch(updateChatMessage(chatMessage));

                }
                catch (error) {
                    console.log("back-off");
                }
                await sleep(100);
            }
        }
    };
};

export const endCall = (value: string) => {
    return async (dispatch: Dispatch, getState: () => RootState): Promise<any> => {

        const state = getState();
        const call = state.call;

        if (call.call) {
            try {
                call.call.hangUp();
            } catch (error) {

            }
            // @ts-ignore
            dispatch(setCall(undefined));
        }

        if (call.callAgent) {
            call.callAgent.dispose();
            // @ts-ignore
            dispatch(setCallAgent(undefined));
        }

        if (call.callClient) {
            // @ts-ignore
            dispatch(setCallClient(undefined));
        }

        if (call.chatThreadClient) {
            // @ts-ignore
            dispatch(setChatThreadClient(undefined));
        }

        if (call.chatClient) {
            call.chatClient.stopRealtimeNotifications();
            // @ts-ignore
            dispatch(setChatClient(undefined))
        }

        // @ts-ignore
        dispatch(setCallState('None'));

        // @ts-ignore
        dispatch(setChatState('None'));

        // @ts-ignore
        dispatch(resetChatMessages(''));


    }
};