import { Call, CallAgent, CallClient, CallState as CallingCallState } from '@azure/communication-calling';
import { ChatClient, ChatThreadClient } from '@azure/communication-chat';
import { ChatMessage } from '@azure/communication-react';
import { Reducer } from 'redux';
import { ADD_CHAT_MESSAGE, CallTypes, RESET_CHAT_MESSAGES, SEND_OFFLINE_CHAT_MESSAGES, SET_CALL, SET_CALL_AGENT, SET_CALL_CLIENT, SET_CALL_STATE, SET_CHAT_CLIENT, SET_CHAT_STATE, SET_CHAT_THREAD_CLIENT, UPDATE_CHAT_MESSAGE } from './callActions';
import { ChatState } from './callTypes';

// Define a type for the slice state
export interface CallState {
    callState: CallingCallState,
    chatState: ChatState,

    callAgent: CallAgent | undefined,
    call: Call | undefined,
    callClient: CallClient | undefined,

    chatClient: ChatClient | undefined,
    chatThreadClient: ChatThreadClient | undefined,
    chatMessages: ChatMessage[],
}

// Define the initial state using that type
export const initialState: CallState = {
    callState: 'None',
    chatState: 'None',
    callClient: undefined,
    callAgent: undefined,
    call: undefined,
    chatClient: undefined,
    chatThreadClient: undefined,
    chatMessages: [],
}

export const callStateReducer: Reducer<CallState, CallTypes> = (state = initialState, action: CallTypes): CallState => {
    switch (action.type) {
        case SET_CALL_STATE:
            return action.value
                ? { ...state, callState: action.value }
                : state;
        case SET_CHAT_STATE:
            return action.value
                ? { ...state, chatState: action.value }
                : state;
        case SET_CALL_AGENT:
            console.log(action.value);
            return { ...state, callAgent: action.value };
        case SET_CALL:
            return { ...state, call: action.value };
        case SET_CALL_CLIENT:
            return { ...state, callClient: action.value };
        case SET_CHAT_CLIENT:
            return { ...state, chatClient: action.value };
        case SET_CHAT_THREAD_CLIENT:
            return { ...state, chatThreadClient: action.value };
        case ADD_CHAT_MESSAGE:
            return action.value
                ? { ...state, chatMessages: [...state.chatMessages, action.value] }
                : state;
        case UPDATE_CHAT_MESSAGE:
            return action.value
                ? { ...state, chatMessages: [...state.chatMessages.filter(m => m.messageId !== action.value?.messageId), action.value] }
                : state;
        case RESET_CHAT_MESSAGES:
            return { ...state, chatMessages: [] };
        case SEND_OFFLINE_CHAT_MESSAGES:
            return state;
        default:
            return state;
    }
};