import { TextField } from '@fluentui/react/lib/TextField';
import './../PickerComponentBase.css';

export type CommentPickerComponentProps = {
  value?: string | undefined;
  onSelectionChanged: (e: string | undefined) => void
}

function CommentPickerComponent(props: CommentPickerComponentProps) {

  const { onSelectionChanged, value } = props;

  return (
    <div className='calendar-comment-picker-component-container'>
      <TextField value={value} label="Any additional comments?" multiline resizable={true} onChange={(e, value) => { onSelectionChanged(value) }} />
    </div >
  );
}

export default CommentPickerComponent;