// GitHub pages routing support using HashRoute: 
// https://www.freecodecamp.org/news/deploy-a-react-app-to-github-pages/
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import ContactPage from './Pages/Contact/ContactPage';
import DevPage from './Pages/Dev/DevPage';

// import BookingPage from './Pages/booking/BookingPage';
// import EmbedPage from './Pages/embed/EmbedPage';
// import FeaturesPage from './Pages/features/FeaturesPage';
// import LandingPage from './Pages/landing/LandingPage';
// import BookingCalendarPage from './Pages/BookingCalendar/BookingCalendarPage';
// import TryMePage from './Pages/tryme/TryMePage';
// import SubscriptionsPage from './Pages/subscriptions/SubscriptionsPage';

const EmbedPage = lazy(() => import('./Pages/embed/EmbedPage'));
const FeaturesPage = lazy(() => import('./Pages/features/FeaturesPage'));
const BookingPage = lazy(() => import('./Pages/booking/BookingPage'));
const BookingCalendarPage = lazy(() => import('./Pages/BookingCalendar/BookingCalendarPage'));
const SubscriptionsPage = lazy(() => import('./Pages/subscriptions/SubscriptionsPage'));

function App() {

  return (

    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path='/' element={<FeaturesPage />} />
          {/* <Route path='/features' element={<FeaturesPage />} /> */}
          <Route path='/subscriptions' element={<SubscriptionsPage />} />
          <Route path='/contact' element={<ContactPage />} />


          {/* <Route path='/tryme' element={<TryMePage />} /> */}

          {/* Special routes - no menu ref */}
          <Route path='/booking' element={<BookingPage />} />
          <Route path='/booking-calendar' element={<BookingCalendarPage />} />
          <Route path='/embed' element={<EmbedPage />} />


          <Route path='/dev' element={<DevPage />} />

        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;