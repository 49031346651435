import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PublicClientApplication } from "@azure/msal-browser";
import { appMode, msalConfig } from './Utils/constants';
import { MsalProvider } from '@azure/msal-react';
import ReactDOM from 'react-dom';
//import { createRoot } from 'react-dom/client';
import { persistor, store } from './App/store'

// import { initializeIcons } from '@fluentui/react/lib/Icons';
import { initializeIcons, registerIcons } from '@fluentui/react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import KisssLocalizationProvider from './Localization/KisssLocalizationProvider';
import { GlobalDebug } from './Utils/GlobalDebug';
import { DEFAULT_COMPONENT_ICONS } from '@azure/communication-react';


GlobalDebug(appMode.debugOn, appMode.suppressAll);

// initializeIcons('https://static2.sharepointonline.com/files/fabric/assets/icons/', { disableWarnings: true });
initializeIcons(/* optional base url */);
registerIcons({ icons: DEFAULT_COMPONENT_ICONS });

const instance = new PublicClientApplication(msalConfig);
// const container = document.getElementById('root');
//const root = createRoot(container!);
require('events').defaultMaxListeners = 70;

ReactDOM.render(
  //root.render(<React.StrictMode>
  <MsalProvider instance={instance}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <KisssLocalizationProvider>
          {/* <LocalizationProvider locale={KISSS_LOCALE_EN_US.acsStrings}> */}
          <App />
          {/* </LocalizationProvider> */}
        </KisssLocalizationProvider>
      </PersistGate>
    </Provider>
  </MsalProvider>
  //</React.StrictMode >
  , document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
