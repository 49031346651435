import { ReactNode } from 'react';
import MenuComponent from '../../components/menu/MenuComponent';
import { useScript } from '../../hooks/useScript';
import { appMode } from '../../Utils/constants';
import './TemplatePageBase.css';

export type MyPropsWithChildren<P> = P & { children?: ReactNode | undefined };

export interface TemplatePageBaseProps {
  subHeader?: string | undefined
  showMenu?: boolean | undefined
  disableHelpMenu?: boolean | undefined

};

function TemplatePageBase({ showMenu, children, disableHelpMenu }: MyPropsWithChildren<TemplatePageBaseProps>) {

  useScript({
    url: "kisss.js?tid=123weABC45",
    scriptAsync: true,
    mode: appMode.mode,
    disabled: disableHelpMenu
  });

  return (
    <>
      {/* <div className='header'>
        <Text variant='xxLarge'>Welcome to KISSS by frwrd.io</Text>
        {props.subHeader && (<Text variant='large'>{props.subHeader}</Text>)}
      </div> */}
      {(showMenu ?? true) && (<MenuComponent />)}
      <div className='page-content'>
        {children}
      </div>
    </>


  );
}

export default TemplatePageBase;
