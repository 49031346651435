import { DefaultButton } from '@fluentui/react/lib/Button';
import { useEffect, useState } from 'react';
import { DisplayMode } from '../PickerComponentTypes';
import './../PickerComponentBase.css';


export type DayPickerComponentProps = {
  month?: Date | undefined;
  from?: Date | undefined;
  displayMode?: DisplayMode | undefined;
  onSelectionChanged: (e: Date | undefined) => void
}

function DayPickerComponent(props: DayPickerComponentProps) {

  const { month, from, onSelectionChanged, displayMode } = props;

  const [selectedDay, setSelectedDay] = useState<Date | undefined>();
  const [availableDays, setAvailableDays] = useState<Date[]>([]);

  const getNumberOfDaysInMonths = (date: Date) => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  };

  useEffect(() => {
    setAvailableDays([]);
    const m = (month ?? new Date(Date.now()));
    const days = getNumberOfDaysInMonths(m);
    const months = Array
      .from(Array(days).keys())
      .map((v) => new Date(m.getFullYear(), m.getMonth(), v + 1));

    setAvailableDays(months);
  }, [month,])

  return (
    <div className='calendar-day-picker-component-container'>
      {availableDays && (availableDays.filter(v => !(displayMode && displayMode === 'Minimized' && selectedDay && selectedDay !== v)).map(v =>
        <DefaultButton disabled={from && v < from} className='toggleButtonStyle' toggle={true} checked={v === selectedDay} key={v.getTime()} onClick={() => {
          if (selectedDay !== v) {
            setSelectedDay(v);
            onSelectionChanged(v);
          }
          else if (selectedDay === v) {
            setSelectedDay(undefined);
            onSelectionChanged(undefined);
          }
        }
        }>{v.toLocaleDateString('da-DK', { weekday: 'short', day: '2-digit' })}</DefaultButton>

      ))}
    </div>
  );
}

export default DayPickerComponent;