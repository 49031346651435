import { DefaultButton, IButtonStyles, PrimaryButton, ProgressIndicator, TextField, Text } from '@fluentui/react';
import { useCallback, useState } from 'react';
import { ChallengeApiClient } from 'Utils/ChallengeApiClient';
import { ChallengeReplyModel, ChallengeResponseModel } from 'Models/Challenge/Models';
import { useEffect } from 'react';
import 'components/styles/SharedStyles.css';

type State = "submit-email" | "error-email" | "retry-email" | "submit-challenge" | "error-challenge" | "success" | "failed" | "error";

export type ChallengeComponentProps2 = {
  onValidated: (email: string, data: ChallengeResponseModel) => void
}

function ChallengeComponent2(props: ChallengeComponentProps2) {

  const [abortController, setAbortController] = useState<AbortController | undefined>(undefined);

  const [state, setState] = useState<State>('submit-email');

  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [emailValue, setEmailValue] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [nonce, setNonce] = useState('');

  const btnClear: IButtonStyles = {
    root: {
      width: '10px',
      padding: 0,
      margin: 0
    }
  };

  const acceptIconProps = { iconName: 'Accept' };
  const backIconProps = { iconName: 'Back' };

  const validateEmail = (email: string): boolean => {
    const regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return email.length > 0 && regEx.test(email);
  };

  const onEmailTextFieldChange = useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      setEmailValue(newValue ?? '');
    }, [/*emailValue*/]);

  const onVerificationCodeFieldChange = useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      setVerificationCode(newValue ?? '');
    }, [/*verificationCode*/]);

  const requestChallenge = useCallback(() => {
    const ac = new AbortController();
    setAbortController(ac);
    setLoading(true);
    setVerificationCode('');
    setErrorMessage('');

    ChallengeApiClient.requestChallenge('email', emailValue, ac.signal)
      .then(model => {
        if (model.success) {
          setState('submit-challenge');
          setErrorMessage('');
          setNonce(model.nonce);
        } else {
          setState('error-email');
          setErrorMessage(model.reason);
          setNonce('');
        }
      }).catch(err => {
        if (err.name === 'AbortError') {
          setErrorMessage('');
        }
        else if (err.name === 'TypeError') {
          setErrorMessage("Sorry! Please try again.");
        }
        else {
          setErrorMessage(`Invalid Email, please try again.`);
        }
        setNonce('');
      }).finally(() => {
        setLoading(false);
        setAbortController(undefined);
      })

  }, [emailValue]);

  const submitVerificationCode = useCallback(() => {
    const ac = new AbortController();
    setAbortController(ac);

    setLoading(true);
    setErrorMessage('');

    const answer: ChallengeReplyModel = {
      nonce: nonce,
      value: emailValue,
      replyValue: verificationCode
    };

    ChallengeApiClient.answerChallenge(answer, ac.signal)
      .then(model => {
        if (model.success) {
          setState('success');
          setErrorMessage('');
          props.onValidated(emailValue, model);
        } else {
          setState('error-challenge');
          setErrorMessage(model.reason);
        }
      })
      .catch(err => {
        setState('error-challenge');
        setErrorMessage("please try again");
      })
      .finally(() => {
        setLoading(false);
        setAbortController(undefined);
      });
  }, [verificationCode, emailValue, nonce, props]);

  const reset = useCallback(() => {
    setState('submit-email');
    setErrorMessage('');
    setEmailValue('');
    setVerificationCode('');
    setNonce('');
    setLoading(false);
  }, []);

  useEffect(() => {
    return () => {
      if (abortController) {
        abortController.abort();
      }
    }
  }, [abortController])

  return (
    <div className='stack horizontal' style={{ maxWidth: '500px' }}>
      <p>
        <Text variant='large'>You need to login with a valid e-mail address to continue.</Text>
      </p>

      {state === 'submit-email' && (<Text variant='medium' style={{ 'color': 'Blue' }}>Please enter a your email below and click 'verify Email'.</Text>)}
      {state === 'submit-challenge' && (<Text variant='medium' style={{ 'color': 'Blue' }}>Please check your email for a verification code. If not in your inbox check your spam folder.</Text>)}
      {state.endsWith('-email') && (<TextField placeholder='Please verify your Email' type='email' value={emailValue} errorMessage={errorMessage} onChange={onEmailTextFieldChange} />)}
      {loading && state.endsWith('-email') && (<ProgressIndicator description="please wait..." />)}

      {state.endsWith('-email') && (
        <div className='stack vertical no-padding space-between auto-height'>
          <PrimaryButton disabled={!validateEmail(emailValue) || loading} onClick={requestChallenge}>Verify Email</PrimaryButton>
          <DefaultButton onClick={() => { setState('submit-email'); setErrorMessage(''); setLoading(false); abortController?.abort(); }} disabled={!loading} iconProps={backIconProps} />
        </div>)}

      {state.endsWith('-challenge') && (<TextField placeholder={`Verification code (${emailValue})`} value={verificationCode} onChange={onVerificationCodeFieldChange} errorMessage={errorMessage} />)}

      {state.endsWith('-challenge') && (
        <div className='stack vertical no-padding space-between'>
          <PrimaryButton disabled={verificationCode.length === 0 || loading} onClick={submitVerificationCode}>Submit Code</PrimaryButton>
          <DefaultButton onClick={() => { setState(loading ? 'submit-challenge' : 'submit-email'); setErrorMessage(''); setLoading(false); abortController?.abort(); }} iconProps={backIconProps} />
        </div>
      )}

      {loading && state.endsWith('-challenge') && (<ProgressIndicator description="please wait..." />)}

      {state.endsWith('success') && (<>
        <div className='stack vertical no-padding space-between'>
          <TextField value={emailValue} readOnly={true} disabled={true} iconProps={acceptIconProps} />
          <DefaultButton styles={btnClear} onClick={reset} iconProps={backIconProps} />
        </div>
      </>)}

    </div>

  );
}

export default ChallengeComponent2;
