import { CommunicationUserToken } from '@azure/communication-identity';
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../store';

// Define a type for the slice state
interface AuthState {
    token: string | undefined,
    email: string | undefined,
    acsToken: CommunicationUserToken | undefined,
}

// Define the initial state using that type
const initialState: AuthState = {
    token: undefined,
    email: undefined,
    acsToken: undefined,
}

export const authSlice = createSlice({
    name: 'auth',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setEmail: (state, action: PayloadAction<string | undefined>) => {
            state.email = action.payload;
        },
        setToken: (state, action: PayloadAction<string | undefined>) => {
            state.token = action.payload;

        },
        setAcsToken: (state, action: PayloadAction<CommunicationUserToken | undefined>) => {
            state.acsToken = action.payload;
        },
    },
})

export const { setEmail, setToken, setAcsToken } = authSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectEmail = (state: RootState) => state.all.auth.email;
export const selectToken = (state: RootState) => state.all.auth.token;
export const selectAcsToken = (state: RootState) => state.all.auth.acsToken;

export default authSlice.reducer