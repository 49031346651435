import { KisssComponentLocale } from "./KisssComponentLocale";


export const KISSS_LOCALE_EN_US: KisssComponentLocale = {
    strings: {
        mainMenu: {
            openBtn: '',
            closedBtn: 'Help',
            bookBtn: 'Book meeting',
            callMeBtn: 'Call me back',
            feedbackBtn: 'Share an idea',
            liveChatBtn: 'Live chat',
            videoCallBtn: 'Video call',
            searchBtn: 'Search guides',
            profileBtn: 'Hi {{email}}'
        },
        bookingPage: {
            noBooking: 'Please open your personal meeting booking (from email og calender) and click the join link and you will join the meeting directly.'
        }
    },
    acsCompositeLocale: {
        component: {
            strings: {
                messageThread: {
                    messageContentAriaText: "",
                    messageContentMineAriaText: "",

                    /** String for Sunday */
                    sunday: "...",
                    /** String for Monday */
                    monday: "...",
                    /** String for Tuesday */
                    tuesday: "...",
                    /** String for Wednesday */
                    wednesday: "...",
                    /** String for Thursday */
                    thursday: "...",
                    /** String for Friday */
                    friday: "...",
                    /** String for Saturday */
                    saturday: "...",
                    /** String for Yesterday */
                    yesterday: "...",
                    /** String for participants joined */
                    participantJoined: "...",
                    /** String for participants left */
                    participantLeft: "...",
                    /** Tag shown on a message that has been edited */
                    editedTag: "...",
                    /** String for editing message in floating menu */
                    editMessage: "...",
                    /** String for removing message in floating menu */
                    removeMessage: "...",
                    /** String for LiveMessage introduction for the Chat Message */
                    liveAuthorIntro: "...",
                    /** String for warning on text limit exceeded in EditBox*/
                    editBoxTextLimit: "...",
                    /** String for placeholder text in EditBox when there is no user input*/
                    editBoxPlaceholderText: "...",
                    /** String for new messages indicator*/
                    newMessagesIndicator: "...",
                    /** String for replacing display name when there is none*/
                    noDisplayNameSub: "...",
                    /** String for Cancel button in EditBox*/
                    editBoxCancelButton: "...",
                    /** String for Submit in EditBox when there is no user input*/
                    editBoxSubmitButton: "...",
                },

                /** Strings for ParticipantItem */
                participantItem: {
                    /** String shown when participant is me */
                    isMeText: "Mig",
                    /** String shown when hovering over menu button */
                    menuTitle: "Muligheder",
                    /** Label for the remove button in participant menu  */
                    removeButtonLabel: "Fjern deltager",
                    /** Label for the sharing icon in participant state stack  */
                    sharingIconLabel: "Del",
                    /** Label for the muted icon in participant state stack  */
                    mutedIconLabel: "Lyd fra",
                },

                /** Strings for CameraButton */
                cameraButton: {
                    /** Label when button is on. */
                    onLabel: "Kamera tændt",
                    /** Label when button is off. */
                    offLabel: "Kamera slukket",
                    /** Tooltip content when the button is disabled. */
                    tooltipDisabledContent: "Du har ikke givet tilladelse til at vi må bruge dit kamera.",
                    /** Tooltip content when the button is on. */
                    tooltipOnContent: "Dit kamera er tændt, tryk her for at slå kamera fra.",
                    /** Tooltip content when the button is off. */
                    tooltipOffContent: "Dit kamera er slukket, tryk her for at slå kamera til.",
                    /**
                     * Title of camera menu
                     */
                    cameraMenuTitle: 'Kamera',
                    /**
                     * Tooltip of camera menu
                     */
                    cameraMenuTooltip: 'Kamera',
                    /**
                     * description of camera button split button role
                     */
                    cameraButtonSplitRoleDescription: 'Kamera split',
                },

                /** Strings for MicrophoneButton */
                microphoneButton: {
                    onLabel: "Mikrofon tændt",
                    /** Label when button is off. */
                    offLabel: "Mikrofon slukket",
                    /** * Tooltip content when the button is disabled. */
                    tooltipDisabledContent: "Du har ikke givet tilladelse til at vi må bruge din mikrofon.",
                    /** Tooltip content when the button is on. */
                    tooltipOnContent: "Din mikrofon er tændt, tryk her for at slå mikrofon fra.",
                    /** Tooltip content when the button is off. */
                    tooltipOffContent: "Din mikrofon er slukket, tryk her for at slå mikrofon til.",
                },

                /** Strings for EndCallButton */
                endCallButton: {
                    /**
                    * Label of button
                    */
                    label: "Forlad",
                    /** Tooltip content. */
                    tooltipContent: "Forlad/læg på",
                },

                /** Strings for DevicesButton */
                devicesButton: {
                    /**
                     * Label of button
                     */
                    label: "Indstillinger",
                    /**
                     * Button tooltip content.
                     */
                    tooltipContent: "Indstillinger for lyd og billede",
                    /**
                     * Title of camera menu
                     */
                    cameraMenuTitle: "Kamera",
                    /**
                     * Title of microphone menu
                     */
                    microphoneMenuTitle: "Mikrofon",
                    /**
                     * Title of speaker menu
                     */
                    speakerMenuTitle: "Højtaler",
                    /**
                     * Tooltip of camera menu
                     */
                    cameraMenuTooltip: "Kamera",
                    /**
                     * Tooltip of microphone menu
                     */
                    microphoneMenuTooltip: "Mikrofon",
                    /**
                     * Tooltip of speaker menu
                     */
                    speakerMenuTooltip: "Højtaler",
                },

                /** Strings for ParticipantsButton */
                participantsButton: {
                    /**
                     * Label of button
                     */
                    label: "Deltagerliste",
                    /**
                     * Button tooltip content.
                     */
                    tooltipContent: "Deltagerliste",
                    /**
                     * Header of menu pop up
                     */
                    menuHeader: "Deltagerliste",
                    /**
                     * Label of menu button to show list of participants. Placeholders: [numParticipants]
                     */
                    participantsListButtonLabel: "Deltagere ({numParticipants})",
                    /**
                     * Label of menu button to copy invite link
                     */
                    copyInviteLinkButtonLabel: "Kopier link til udklipsholder",
                    /**
                     * Label of menu button to mute all participants
                     */
                    muteAllButtonLabel: "Slå lyd fra for alle",
                },

                /** Strings for ScreenShareButton */
                screenShareButton: {
                    /** Label when button is on. */
                    onLabel: "Skærmdeling",
                    /** Label when button is off. */
                    offLabel: "Skærmdeling",
                    /** * Tooltip content when the button is disabled. */
                    tooltipDisabledContent: "Du har ikke mulighed for at dele din skærm",
                    /** Tooltip content when the button is on. */
                    tooltipOnContent: "Stop deling af skærm",
                    /** Tooltip content when the button is off. */
                    tooltipOffContent: "Del din skærm med de andre deltagere",
                },

                /** Strings for TypingIndicator */
                typingIndicator: {
                    /**
                     * String template to use when one user is typing. Placeholders: [user].
                     * @example
                     * ```
                     * <TypingIndicator
                     *  strings={{ multipleUsersAbbreviateOne: '{users} is typing...' }}
                     *  typingUsers={[{ userId: 'user1', displayName: 'Claire' }]}
                     * />
                     * ```
                     * would be 'Claire is typing...'
                     **/
                    singleUser: "...",
                    /**
                     * String template to use when multiple users are typing. Placeholders: [users].
                     * @example
                     * ```
                     * <TypingIndicator
                     *  strings={{ multipleUsers: '{users} are typing...' }}
                     *  typingUsers={[
                     *    { userId: 'user1', displayName: 'Claire' },
                     *    { userId: 'user2', displayName: 'Chris' }
                     *  ]}
                     * />
                     * ```
                     * would be 'Claire, Chris are typing...'
                     **/
                    multipleUsers: "...",
                    /**
                     * String template to use when multiple users are typing with one other user abbreviated. Placeholders: [users].
                     * @example
                     * ```typescript
                     * <TypingIndicator
                     * strings={{ multipleUsersAbbreviateOne: '{users} and 1 other are typing...' }}
                     * typingUsers={[
                     * { userId: 'user1', displayName: 'Claire Romanov' },
                     * { userId: 'user2', displayName: 'Chris Rutherford' }
                     * ]}
                     * />
                     * ```
                     * would be 'Claire Romanov and 1 other are typing...'
                     **/
                    multipleUsersAbbreviateOne: "...",
                    /**
                     * String template to use when multiple users are typing with one other user abbreviated. Placeholders: [users, numOthers].
                     * @example
                     * ```
                     * <TypingIndicator
                     *  strings={{ multipleUsersAbbreviateMany: '{users} and {numOthers} others are typing...' }}
                     *  typingUsers={[
                     *    { userId: 'user1', displayName: 'Claire Romanov' },
                     *    { userId: 'user2', displayName: 'Chris Rutherford' },
                     *    { userId: 'user3', displayName: 'Jill Vernblom' }
                     *  ]}
                     * />
                     * ```
                     * would be 'Claire Romanov and 2 others are typing...'
                     **/
                    multipleUsersAbbreviateMany: "...",
                    /**
                     * String to use as delimiter to separate multiple users.
                     * @example
                     * ```
                     * <TypingIndicator
                     *  strings={{ delimiter: ' + ' }}
                     *  typingUsers={[
                     *    { userId: 'user1', displayName: 'Claire' },
                     *    { userId: 'user2', displayName: 'Chris' },
                     *    { userId: 'user3', displayName: 'Jill' }
                     *  ]}
                     * />
                     * ```
                     * would be 'Claire + Chris + Jill are typing...'
                     **/
                    delimiter: " + ",
                },

                /** Strings for SendBox */
                sendBox: {
                    /**
                     * Placeholder text in SendBox when there is no user input
                     */
                    placeholderText: "...",
                    /**
                     * The warning message when send box text length is more than max limit
                     */
                    textTooLong: "...",
                    /**
                     * Aria label for send message button
                     */
                    sendButtonAriaLabel: "..."
                },

                /** Strings for MessageStatusIndicator */
                messageStatusIndicator: {
                    /** Text to display in the delivered message icon tooltip. */
                    deliveredTooltipText: "...",
                    /** Text to display in the seen message icon tooltip. */
                    seenTooltipText: "...",
                    /** Text to display in the sending message icon tooltip. */
                    sendingTooltipText: "...",
                    /** Text to display in the failed message icon tooltip. */
                    failedToSendTooltipText: "...",
                },

                /** Strings for ErroBar */
                errorBar: {
                    callMicrophoneUnmutedBySystem: "",
                    callVideoStoppedBySystem: "",
                    callVideoRecoveredBySystem: "",

                    /**
                    * Unable to reach Chat service.
                    *
                    * This can mean:
                    *   - Incorrect Azure Communication Services endpoint was provided.
                    *   - User's network connection is down.
                    */
                    unableToReachChatService: "...",
                    /**
                     * User does not have access to the Chat service.
                     * This usually means that either the Azure Communication Services endpiont or the token provided are incorrect.
                     */
                    accessDenied: "Ingen adgang",
                    /**
                     * User is no longer on the thread.
                     *
                     * See also: {@link ErrorBarStrings.sendMessageNotInChatThread} for a more specific error.
                     */
                    userNotInChatThread: "...",
                    /**
                     * Sending message failed because user is no longer on the thread.
                     */
                    sendMessageNotInChatThread: "...",
                    /**
                     * A generic message when sending message fails.
                     * Prefer more specific error strings when possible.
                     */
                    sendMessageGeneric: "...",
                    /**
                     * A generic message when starting video fails.
                     */
                    startVideoGeneric: "Kunne ikke starte video, prøv igen",
                    /**
                     * A generic message when starting video fails.
                     */
                    stopVideoGeneric: "Kunne ikke stoppe video, prøv igen",
                    /**
                     * A generic message when muting microphone fails.
                     */
                    muteGeneric: "Kunne ikke slukke mikrofon, prøv igen",
                    /**
                     * A generic message when unmuting microphone fails.
                     */
                    unmuteGeneric: "Kunne ikke tænde mikrofon, prøv igen",
                    /**
                     * A generic message when starting screenshare fails.
                     */
                    startScreenShareGeneric: "Kunne ikke starte skærmdeling, prøv igen",
                    /**
                     * A generic message when stopping screenshare fails.
                     */
                    stopScreenShareGeneric: "Kunne ikke stoppe skærmdeling, prøv igen",
                    /**
                     * Message shown when poor network quality is detected during a call.
                     */
                    callNetworkQualityLow: "Internet forbindelsen er ikke optimal, video og lyd er derfor forringet.",
                    /**
                     * Message shown on failure to detect audio output devices.
                     */
                    callNoSpeakerFound: "Ingen højtaler tilsluttet.",
                    /**
                     * Message shown on failure to detect audio input devices.
                     */
                    callNoMicrophoneFound: "Ingen mikrofon tilsluttet.",
                    /**
                     * Message shown when microphone can be enumerated but access is blocked by the system.
                     */
                    callMicrophoneAccessDenied: "Der er ikke adgang til mikrofonen",
                    /**
                     * Message shown when microphone is muted by the system (not by local or remote participants)
                     */
                    callMicrophoneMutedBySystem: "Din mikrofon er slået fra på systemet. Tjek dine system indstillinger.",
                    /**
                     * Mac OS specific message shown when microphone can be enumerated but access is
                     * blocked by the system.
                     */
                    callMacOsMicrophoneAccessDenied: "Din Mac har ikke givet adgang til at bruge mikrofonen i din browser. Tjek dine system indstillinger.",
                    /**
                     * Message shown when poor network causes local video stream to be frozen.
                     */
                    callLocalVideoFreeze: "Netværket er ikke optimalt og video/lyd kan derfor fryse.",
                    /**
                     * Message shown when camera can be enumerated but access is blocked by the system.
                     */
                    callCameraAccessDenied: "Ingen adgang til kamera. Tjek indstillinger.",
                    /**
                     * Message shown when local video fails to start because camera is already in use by
                     * another applciation.
                     */
                    callCameraAlreadyInUse: "Kamera allerede i brug. Tjek om andre programmer burger kamera.",
                    /**
                     * Mac OS specific message shown when system denies access to camera.
                     */
                    callMacOsCameraAccessDenied: "Din Mac har ikke givet adgang til at bruge kamera i din browser. Tjek dine system indstillinger.",
                    /**
                     * Mac OS specific message shown when system denies sharing local screen on a call.
                     */
                    callMacOsScreenShareAccessDenied: "Din Mac har ikke givet adgang til at kunne dele skærm via din browser. Tjek dine system indstillinger.",
                },

                /** Strings for VideoGallery */
                videoGallery: {
                    /** String to notify that local user is sharing their screen */
                    screenIsBeingSharedMessage: "Din skærm deles nu med de andre deltagere!",
                    /** String to show when remote screen share stream is loading */
                    screenShareLoadingMessage: "Starter skærmdeling...",
                    /** String for local video label. Default is "You" */
                    localVideoLabel: "Mig",
                    /** String for local video camera switcher */
                    localVideoCameraSwitcherLabel: 'Skift kamera',
                    localVideoMovementLabel: '',
                    localVideoSelectedDescription: '',
                    displayNamePlaceholder: '',
                }
            }
        },
        strings: {
            call: {
                copyInviteLinkActionedAriaLabel: "",

                cameraLabel: "Camera",
                cameraPermissionDenied: "Your browser is blocking access to your camera",
                cameraTurnedOff: "Your camera is turned off",
                close: "Close",
                complianceBannerNowOnlyRecording: "You are now only recording this meeting.",
                complianceBannerNowOnlyTranscription: "You are now only transcribing this meeting.",
                complianceBannerRecordingAndTranscriptionSaved: "Recording and transcription are being saved.",
                complianceBannerRecordingAndTranscriptionStarted: "Recording and transcription have started.",
                complianceBannerRecordingAndTranscriptionStopped: "Recording and transcription have stopped.",
                complianceBannerRecordingSaving: "Recording is being saved.",
                complianceBannerRecordingStarted: "Recording has started.",
                complianceBannerRecordingStopped: "Recording has stopped.",
                complianceBannerTranscriptionStarted: "Transcription has started.",
                complianceBannerTranscriptionConsent: "By joining, you are giving consent for this meeting to be transcribed.",
                complianceBannerTranscriptionSaving: "Transcription is being saved.",
                complianceBannerTranscriptionStopped: "Transcription has stopped.",
                configurationPageTitle: "_Start a call_",
                defaultPlaceHolder: "Select an option",
                failedToJoinCallDueToNoNetworkMoreDetails: "Call was disconnected due to a network issue. Check your connection and join again.",
                failedToJoinCallDueToNoNetworkTitle: "Call disconnected",
                failedToJoinTeamsMeetingReasonAccessDeniedMoreDetails: "You were not granted entry in the call. If this was a mistake, re-join the call.",
                failedToJoinTeamsMeetingReasonAccessDeniedTitle: "Dismissed from lobby",
                learnMore: "Learn more",
                leftCallMoreDetails: "If this was a mistake, re-join the call.",
                leftCallTitle: "You left the call",
                lobbyScreenConnectingToCallTitle: "Joining call",
                lobbyScreenWaitingToBeAdmittedTitle: "_Waiting to be admitted_",
                microphonePermissionDenied: "Your browser is blocking access to your microphone",
                mutedMessage: "You're muted",
                networkReconnectMoreDetails: "Looks like something went wrong. We're trying to get back into the call.",
                networkReconnectTitle: "Hold on",
                privacyPolicy: "Privacy policy",
                rejoinCallButtonLabel: "Re-join call",
                removedFromCallMoreDetails: "Another participant removed you from the call.",
                removedFromCallTitle: "You were removed",
                soundLabel: "Sound",
                startCallButtonLabel: "_Start call_",
                microphoneToggleInLobbyNotAllowed: "Cannot mute or unmute while in lobby."
            },
            chat: {
                chatListHeader: "In this chat",
            },
            callWithChat: {
                copyInviteLinkActionedAriaLabel: "",


                peopleButtonLabel: "People",
                peopleButtonTooltipOpen: "Show participants",
                peopleButtonTooltipClose: "Hide participants",
                chatButtonLabel: "Chat",
                chatButtonTooltipClosedWithMessageCount: "Show chat ({unreadMessagesCount} unread)",
                chatButtonTooltipOpen: "Show chat",
                chatButtonTooltipClose: "Hide chat",
                moreDrawerAudioDeviceMenuTitle: "Audio Device",
                moreDrawerMicrophoneMenuTitle: "Microphone",
                moreDrawerSpeakerMenuTitle: "Speaker",
                moreDrawerButtonLabel: "More options",
                moreDrawerButtonTooltip: "More options",
                peoplePaneTitle: "People",
                peoplePaneSubTitle: "In this call",
                chatPaneTitle: "Chat",
                chatButtonNewMessageNotificationLabel: "New Message",
                pictureInPictureTileAriaLabel: "Video Feeds. Click to return to call screen.",
                removeMenuLabel: "Remove",
                copyInviteLinkButtonLabel: "Copy invite link",
                dismissSidePaneButtonLabel: "Close",
                returnToCallButtonAriaDescription: "Return to Call",
                returnToCallButtonAriaLabel: "Back"
            }
        }
    }
}