import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from './features/counter/counterSlice';
import authReducer from './features/auth/authSlice';


import { persistStore, persistReducer } from 'redux-persist'
//import { default as storage } from 'redux-persist/lib/storage' // defaults to localStorage for web
import { default as storage } from 'redux-persist/lib/storage/session' // defaults to localStorage for web
import { combineReducers } from 'redux'
import thunk from 'redux-thunk';
import { callStateReducer } from './features/call/callReducer';

const rootReducer = combineReducers({
    counter: counterReducer,
    auth: authReducer,
});

const persistConfig = {
    key: 'root',
    storage: storage,
    blacklist: ['call'],
    // whitelist: ['value']
}

// https://www.npmjs.com/package/redux-persist
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: {
        //counter: persistedReducer,
        all: persistedReducer,
        call: callStateReducer
    },
    middleware: [thunk]
});
export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;