import { DefaultButton } from '@fluentui/react/lib/Button';
import { useEffect, useState } from 'react';
import { DisplayMode, TimeInterval } from '../PickerComponentTypes';
import './../PickerComponentBase.css';

export type TimeSlotPickerComponentProps = {
  day?: Date | undefined;
  from?: Date | undefined;
  displayMode?: DisplayMode | undefined;
  firstHour: number;
  firstMinute: number;
  lastHour: number;
  lastMinute: number;
  timeSlotMinutes: number;
  timeSlotBreakMinutes: number;
  onSelectionChanged: (e: TimeInterval | undefined) => void
}

function TimeSlotPickerComponent(props: TimeSlotPickerComponentProps) {

  const { day, from, firstHour, firstMinute, lastHour, lastMinute, timeSlotMinutes, timeSlotBreakMinutes, onSelectionChanged, displayMode } = props;

  const [selectedTimeSlot, setSelectedTimeSlot] = useState<TimeInterval | undefined>();
  const [availableHours, setAvailableHours] = useState<TimeInterval[]>([]);

  const getNumberOfHoursInDaySlot = (date: Date, firstHour: number, lastHour: number) => {
    const start = new Date(date.getFullYear(), date.getMonth(), date.getDate(), firstHour, 0, 0, 0);
    const end = new Date(date.getFullYear(), date.getMonth(), date.getDate(), lastHour, 0, 0, 0);
    const hours = Math.abs(end.getTime() - start.getTime()) / 36e5;
    return hours;
  };

  const addLeadingZero = (num: number) => {
    return String(num).padStart(2, '0');
  }

  useEffect(() => {
    setAvailableHours([]);
    const d = (day ?? new Date(Date.now()));
    const numberOfHours = getNumberOfHoursInDaySlot(d, firstHour, lastHour) + lastMinute > 0 ? lastMinute : 0;
    const numberOfMinutes = numberOfHours * 60;
    const interval = timeSlotMinutes + timeSlotBreakMinutes;
    const firstAllowedSlot = new Date(d.getFullYear(), d.getMonth(), d.getDate(), firstHour, firstMinute, 0, 0);
    const lastAllowedSlot = new Date(d.getFullYear(), d.getMonth(), d.getDate(), lastHour, lastMinute, 0, 0);

    var helper: TimeInterval[] = [];
    for (let index = 0; index <= numberOfMinutes; index += interval) {
      const start = new Date(d.getFullYear(), d.getMonth(), d.getDate(), firstHour, index, 0, 0);
      const end = new Date(d.getFullYear(), d.getMonth(), d.getDate(), firstHour, index + timeSlotMinutes, 0, 0);
      const timeInterval: TimeInterval = { from: start, to: end };

      if (start >= firstAllowedSlot && end <= lastAllowedSlot) {
        // console.log(end.getHours());
        helper.push(timeInterval);
      }
    }

    setAvailableHours(helper);
  }, [day, lastHour, lastMinute, firstHour, firstMinute, timeSlotBreakMinutes, timeSlotMinutes])

  return (
    <div className='calendar-time-slot-picker-component-container'>
      {availableHours && (availableHours.filter(v => !(displayMode && displayMode === 'Minimized' && selectedTimeSlot && selectedTimeSlot.from !== v.from)).map(v =>
        <DefaultButton disabled={from && v.from < from} className='toggleButtonStyle' toggle={true} checked={v.from === selectedTimeSlot?.from} key={v.from.getTime()} onClick={() => {
          if (selectedTimeSlot?.from !== v.from) {
            setSelectedTimeSlot(v);
            onSelectionChanged(v);
          }
          else if (selectedTimeSlot?.from === v.from) {
            setSelectedTimeSlot(undefined);
            onSelectionChanged(undefined);
          }
        }
        }>{`${addLeadingZero(v.from.getHours())}:${addLeadingZero(v.from.getMinutes())} - ${addLeadingZero(v.to.getHours())}:${addLeadingZero(v.to.getMinutes())}`}</DefaultButton>
      ))}
    </div>
  );
}

export default TimeSlotPickerComponent;