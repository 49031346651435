// © frwrd Corporation. All rights reserved.
import { IPublicClientApplication, SilentRequest } from '@azure/msal-browser';
import { HttpClientHeaders } from './HttpClientHeaders';

export class ApiClientT {

  _headerWithAuth(method: 'get' | 'post' | 'put' | 'delete', accessToken: string, body?: any): RequestInit {
    switch (method) {
      case 'post':
        return HttpClientHeaders.httpPostSettingsWithAccessToken(body, accessToken);
      case 'put':
        return HttpClientHeaders.httpPutSettingsWithAccessToken(body, accessToken);
      case 'delete':
        return HttpClientHeaders.httpDeleteSettingsWithAccessToken(accessToken);
      default:
        return HttpClientHeaders.httpGetHeadersWithAccessToken(accessToken);
    }
  };

  _header(method: 'get' | 'post' | 'put' | 'delete', abortSignal: AbortSignal, body?: any): RequestInit {
    switch (method) {
      case 'post':
        return HttpClientHeaders.httpPostSettings(body, abortSignal);
      case 'put':
        return HttpClientHeaders.httpPutSettings(body);
      case 'delete':
        return HttpClientHeaders.httpDeleteSettings();
      default:
        return HttpClientHeaders.httpGetHeaders(abortSignal);
    }
  };


  send<Type>(method: 'get' | 'post' | 'put' | 'delete', baseUrl: string, abortSignal: AbortSignal, body?: any): Promise<Type> {
    // const errorMessage = 'Invalid response ';

    return new Promise((resolve, reject) => {
      const url = baseUrl;

      fetch(url, this._header(method, abortSignal, body))
        .then(response => {
          if (response.ok) {
            resolve(response.json());
          }
          else {
            reject(response.statusText);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  sendWithAuth<Type>(instance: IPublicClientApplication, method: 'get' | 'post' | 'put' | 'delete', baseUrl: string, scopes: string[], body?: any): Promise<Type> {
    // const errorMessage = 'Invalid response ';

    return new Promise((resolve, reject) => {
      const silentRequest: SilentRequest = {
        scopes: scopes,
        account: instance.getActiveAccount()!
      };

      const url = baseUrl;
      // Silently acquires an access token which is then attached to a request for api calls
      instance.acquireTokenSilent(silentRequest)
        .then((token) => {
          // Use access to in call to protected api
          // console.log(token.accessToken);
          // console.log(token.idToken);
          fetch(url, this._headerWithAuth(method, token.accessToken, body))
            .then(response => {
              console.log(`(1) | ${response.status} | (${method})${url} | ${JSON.stringify(body)}`);

              if (response.ok) {
                console.log(`ok(1) | ${response.status} | (${method})${url}`);
                resolve(response.json());
              }
              else {
                console.log(`reject(1) | ${response.status} | (${method})${url}`);
                reject(response.statusText);
              }
            })
            .catch(error => {
              console.log(`acquireTokenSilent reject(1) | ${error} | (${method})${url}`);
              reject(error);
            });
        }).catch((e) => {
          instance.loginRedirect(silentRequest)
            .then(() => {
              // Use access to in call to protected api
              instance.acquireTokenSilent(silentRequest).then((token) => {
                console.log(`acquireTokenSilent ok(2)`);
                // Use access to in call to protected api
                fetch(url, this._headerWithAuth(method, token.accessToken, body))
                  .then(response => {
                    console.log(`ok(2) | ${response.status} | (${method})${url}`);
                    resolve(response.json());
                  })
                  .catch(error => {
                    console.log(`reject(2) | ${error} | (${method})${url}`);
                    reject(error);
                  });
              })
                .catch(error => {
                  console.log(`acquireTokenSilent reject(2) | ${error}`);
                  reject(error);
                });
            })
            .catch(error => {
              console.log(`login reject(2) | ${error}`);
              reject(error);
            });
        });
    });
  };

  sendWithBearerToken<Type>(bearerToken: string, method: 'get' | 'post' | 'put' | 'delete', baseUrl: string, scopes: string[], body?: any): Promise<Type> {
    return new Promise((resolve, reject) => {

      const url = baseUrl;

      fetch(url, this._headerWithAuth(method, bearerToken, body))
        .then(response => {
          console.log(`(1) | ${response.status} | (${method})${url} | ${JSON.stringify(body)}`);

          if (response.ok) {
            console.log(`ok(1) | ${response.status} | (${method})${url}`);
            resolve(response.json());
          }
          else {
            console.log(`reject(1) | ${response.status} | (${method})${url}`);
            reject(response.statusText);
          }
        })
        .catch(error => {
          console.log(`acquireTokenSilent reject(1) | ${error} | (${method})${url}`);
          reject(error);
        });
    })
  };
};