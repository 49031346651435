import { CompositeLocale } from "@azure/communication-react";
import { createContext, useContext } from "react";
import { KisssComponentLocale } from "./KisssComponentLocale";
import { KISSS_LOCALE_DA_DK } from "./KISSS_LOCALE_DA_DK";
import { KISSS_LOCALE_EN_US } from "./KISSS_LOCALE_EN_US";
import { AcsLocalizationProvider } from "./AcsLocalizationProvider";

export const KisssLocaleContext = createContext<KisssComponentLocale>(KISSS_LOCALE_EN_US);
export const acsLocaleContext = createContext<CompositeLocale>(KISSS_LOCALE_EN_US.acsCompositeLocale);


export const useKissLocale = (): KisssComponentLocale => useContext(KisssLocaleContext);

export type LocalizationProviderProps = {
    /** Locale context to provide components */
    locale?: KisssComponentLocale;
    /** Children to provide locale context. */
    children: React.ReactNode;
};

// Perhaps replace with: https://www.i18next.com/overview/getting-started
function KisssLocalizationProvider(props: LocalizationProviderProps) {
    const fallbackLocal = useKissLocale();
    const { locale, children } = props;

    const detectLanguage = (fallback: KisssComponentLocale): KisssComponentLocale => {
        const lang = navigator.language.toLowerCase();
        switch (lang) {
            case 'en-us':
                return KISSS_LOCALE_EN_US;
            case 'da-dk':
                return KISSS_LOCALE_DA_DK;
            default:
                return fallbackLocal;
        }
    }

    return (
        <KisssLocaleContext.Provider value={locale ?? detectLanguage(fallbackLocal)}>
            <AcsLocalizationProvider locale={locale && locale.acsCompositeLocale && locale.acsCompositeLocale ? locale.acsCompositeLocale : detectLanguage(fallbackLocal).acsCompositeLocale}>
                {children}
            </AcsLocalizationProvider>
        </KisssLocaleContext.Provider>
    );
}

export default KisssLocalizationProvider;